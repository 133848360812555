@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Protest+Revolution&family=Sixtyfour:SCAN@-30&display=swap');

* {
  min-width: 0;
}

:root {

}
html {
  scroll-behavior: smooth;
}


/* add bg_shades.png to background with 50% opacity */
body {
  background: url("./images/bg_shades_small.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;

  background-blend-mode: overlay;
  background-color: rgb(22, 22, 22);

}

body {
  margin: 0;
}

.main-color{
  background-color: rgb(116, 106, 85);
}

body::-webkit-scrollbar {
  width: 0.5rem;
}
body::-webkit-scrollbar-track {
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(213, 0, 110);
}

h2 {
  font-size: 2rem;
  font-family: 'Protest Revolution', sans-serif;
  font-weight: 400;
}

h3 {
  font-size: 1.5rem;
}

.front_font{
  font-family: 'Protest Revolution', sans-serif;
  font-weight: 400;
}

.grid-bg {
  @apply bg-neutral-900 bg-opacity-25 border-solid border-2 border-pink-950;
}

